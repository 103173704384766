<template>
  <div>
    <va-input
      v-if="edit"
      v-model="editValue"
      :placeholder="placeHolder"
      @keyup.enter="valueLocal = editValue; edit = false; $emit('input', valueLocal); "
      @blur="edit=false"
      @keyup.esc="edit = false"
      v-focus=""
    />
    <p
      v-else
      @dblclick="editModeOn"
      style="cursor: pointer; text-decoration: underline;"
    >
      {{ valueLocal }}
    </p>
  </div>
</template>

<script>
export default {

  props: ['value'],

  data() {
    return {
      edit: false,
      valueLocal: this.value,
      editValue: '',
      placeHolder: 'Type your text here',
    };
  },

  watch: {
    value: function() {
      this.valueLocal = this.value;
    },
  },
  methods: {
    editModeOn() {
      this.editValue = this.valueLocal;
      this.edit = true;
    },
    editModeOff() {
      this.edit = false;
    },
  },

  directives: {
    focus: {
      inserted(el) {
        el.focus();
      },
    },
  },

};
</script>
