export default [
  '',
  'L_Pen',
  'R_Pen',
  'L_ImagesCard1',
  'L_ImageCard2',
  'L_LR_ImageCard1',
  'L_LR_ImageCard2',
  'L_PhraseCard1',
  'L_PhraseCard2',
  'R_ImageCard1',
  'R_LR_ImageCard1',
  'R_ImageCard2',
  'R_LR_ImageCard2',
  'R_PhraseCard1',
  'R_PhraseCard2',
];
